<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="navbar-title">
        <img class="navbar-haya-logo" src="@/assets/misc/haya-main-logo.png" />
      </ion-title>
      <ion-button fill="solid" slot="end" class="navbar-button" @click="logoutDialog">
        <ion-icon slot="start"  name="exit-outline"></ion-icon>
        Logout
      </ion-button>
    </ion-toolbar>
  </ion-header>
</template>
<script>
import { 
  alertController, 
  loadingController, 
  toastController, 
  IonIcon, 
  IonTitle, 
  IonToolbar, 
  IonButton,
  IonHeader
} from '@ionic/vue';

import store from '../../store/index';
import VueCookies from 'vue-cookies'

export default {
  components: {
    IonIcon, IonTitle, IonToolbar, IonButton, IonHeader
  },
  methods: {
    async dismissLoading() {
      const loading = await loadingController;

      loading.dismiss();
    },
    async presentLoading() {
      const loading = await loadingController
          .create({
          cssClass: 'loading-container',
          message: 'Logging out. Please wait...',
          duration: this.timeout,
          });

      await loading.present();
    },
    async openToast(msg, type) {
      const toast = await toastController
          .create({
              message: msg,
              animated: true,
              duration: 5000,
              color: type,
              end: true
          })
      return toast.present();
    },
    async logoutDialog() {
        const alert = await alertController.create({
            cssClass: 'alert-container',
            header: 'Logout User',
            backdropDismiss: false,
            message: 'Are you sure you want to logout? Any unsaved changes will be lost.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.logout();
                    },
                },
            ],
        });

        return alert.present();
    },
    async logout() {
      const that = this;
      this.presentLoading();

      await store.dispatch('user/logout').then(() => {
        localStorage.removeItem("is_consent_agreed");
        VueCookies.remove("misc");
        VueCookies.remove("misc-hs");
        that.dismissLoading();

        setTimeout(function() {
          window.location.href = '/login';
          that.openToast('Successfully logout', 'success')
        }, 1000);
      }).catch((err) => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }

        const that = this;

        that.dismissLoading();
        setTimeout(function() {
            that.openToast('Something went wrong. Please try again', 'danger')
        }, 1000);
      });
    }
  }
};
</script>
<style scoped>
  .navbar-haya-logo {
    height: 45px;
    width: auto;
    position: relative;
    top: 4px;
    right: 0;
  }
  
  .navbar-button {
    position: relative;
    right: 15px
  }
</style>