<template>
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-list v-if="loading === false ">
      <ion-list-header class="history-header">
        <h1>Health Monitoring Log History</h1>
      </ion-list-header>
      <ion-item v-for="(item, index) in getAnsweredList" :key="index" class="history-list-container">
        <ion-avatar slot="start" class="history-icon">
          <ion-icon v-if="item.has_symptoms === false" name="happy-sharp" size="large" color="success"></ion-icon>
          <ion-icon v-if="item.has_symptoms === true" name="sad-sharp" size="large" color="danger"></ion-icon>
        </ion-avatar>
        <ion-label class="history-content">
          <p class="history-datetime">{{item.date}}</p>
          <p class="history-status">{{item.has_symptoms === false ? 'Feeling Well' : 'Not Feeling Well'}}</p>
          <p class="history-message">{{item.has_symptoms === false ? 'Stay safe and follow health protocols.' : 'If symptom worsens, please seek immediate medical attention and inform HR.'}}</p>
        </ion-label>
        <ion-button shape="round" color="default" fill="outline" @click="viewForm(item.id)">
          <ion-icon name="eye-outline" color="default" slot="start"></ion-icon> View
        </ion-button>
      </ion-item>
      <ion-text class="no-results-container" v-if="getAnsweredList.length < 1">
        <h1>No Results</h1>
        <p>Looks like you haven't filed anything yet</p>
      </ion-text>
    </ion-list>
    
    <div v-if="loading === true">
      <ion-list>
        <ion-list-header class="history-header">
          <h1>Health Monitoring Log History</h1>
        </ion-list-header>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ion-content>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { IonText, IonButton, IonSkeletonText, IonContent, IonRefresher, IonRefresherContent, IonListHeader, IonIcon, IonAvatar, IonLabel, IonItem, IonList } from '@ionic/vue';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

export default {
  name: 'HistoryContainer',
  components: { IonText, IonButton, IonSkeletonText, IonContent, IonRefresher, IonRefresherContent, IonListHeader, IonIcon, IonAvatar, IonLabel, IonItem, IonList },
  props: {
    answeredForms: {
			type: Array
		},
    userData: {
			type: Object
		},
    isPageLoading: {
			type: Boolean
		},
  },
  data() {
    return {
      getAnsweredList: [],
      loading: true
    };
  },
  computed: {
		...mapGetters([
			"login/currUser",
		]),
	},
  watch: {
    isPageLoading: {
			handler(val) {
        this.loading = val;
      },
			deep: true
		},
    answeredForms: {
      handler(val) {
        this.getData(val);
      },
      deep: true
    }
  },
  methods: {
    doRefresh(event) {
      this.loading = true;
      let current_user = {};

      let bytes = "";
			let decryptedData = "";

			let cipherData = VueCookies.get('misc');

			if (this.userData) {
				current_user = this.userData;
			} else {
				bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
				decryptedData = bytes.toString(CryptoJS.enc.Utf8);

				if (decryptedData) {
					let decode = JSON.parse(decryptedData);
					let user = decode.user;
					current_user = user;
				} else {
					localStorage.setItem('is_session_expired', true);
					localStorage.removeItem("is_consent_agreed");
					VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
					window.location.href = '/login';
				}
			}

      
      store.dispatch('questionnaire/getAnsweredQuestionaires', current_user.id).then(res => {
        const data = res.data;

        this.getData(data);
        event.target.complete();
        this.verifyAuth();
      }).catch(err => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }
        
        this.loading = false;
        event.target.complete();
      });
    },
    async verifyAuth() {
      let parsed_user = {};

      await store.dispatch('login/verifyAuth').then(() => {
        parsed_user = JSON.parse(store.getters['login/currUser']);
        return;
      }).catch(err => {
        if (err.status == 401) {
          localStorage.setItem('is_session_expired', true);
          localStorage.removeItem("is_consent_agreed");
          VueCookies.remove("misc");
          VueCookies.remove("misc-hs");
          window.location.href = '/login';
        }
      });

      if (parsed_user.employee_no === null || parsed_user.employee_no === "" || parsed_user.employee_no === undefined) {
        this.$router.push('/onboard')
      } else {
        return;
      }
    },
    sortByLatestDate(a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    },
    getData(data) {
      data.forEach(item => {
        item.date = format(new Date(item.date).getTime(), "PP")
      });
      data.sort(this.sortByLatestDate);
      this.getAnsweredList = data;
      this.loading = false;
    },
    viewForm(id) {
			this.$router.push(`/view-health-form/${id}`, {
				form_id: id
			});
		},
  },
};
</script>
<style scoped>
  .history-header {
    font-family: "Roboto Bold";
  }

  .history-icon {
    margin-right: 10px;
  }

  .history-status {
    font-family: "Roboto Regular";
    font-weight: bold;
  }

  .no-results-container {
    text-align: center;
    width: 320px;
    margin: auto;
  }

  .no-results-icon {
    font-size: 50px;
  }

  .no-results-container h1 {
    font-family: "Roboto Bold";
  }
</style>